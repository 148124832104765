import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import {reducer as formReducer} from 'redux-form';

import MarketLayout from 'web/components/market_layout';
import {actions as marketLayoutActions} from 'web/components/market_layout/actions';
import Alert from 'web/components/alert';
import {DisplayTotals} from 'web/components/totals';
import {formatPromoCode} from 'infra/formatters/money';
import {deliveryTimeLabel} from 'infra/formatters/time';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';

import OrderPanel from '../components/order_panel';
import {reducer as orderItemsReducer} from './ducks/order_items';
import {reducer as deliveryTimeReducer} from './ducks/delivery_time';
import {reducer as cancelOrderReducer} from './ducks/cancel_order';
import {reducer as deliveryAddressReducer} from './ducks/delivery_address';
import {reducer as paymentDetailsReducer} from './ducks/payment_details';
import CancelOrder from './components/cancel_order';
import DeliveryAddress from './components/delivery_address';
import DeliveryTime from './components/delivery_time';
import PaymentDetails from './components/payment_details';
import OrderItems from './components/order_items';

class SingleOrderPage extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  static reducer(state, action) {
    let newState = MarketLayout.reducer(state, action);
    newState = orderItemsReducer(newState, action);
    newState = deliveryTimeReducer(newState, action);
    newState = deliveryAddressReducer(newState, action);
    newState = cancelOrderReducer(newState, action);
    newState = paymentDetailsReducer(newState, action);
    newState.form = formReducer(newState.form, action);
    return newState;
  }

  constructor(props) {
    super(props);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onOpenModal() {
    this.props.dispatch(marketLayoutActions.openModal());
  }

  onCloseModal() {
    this.props.dispatch(marketLayoutActions.closeModal());
  }

  render() {
    const clientSettings = this.context;
    const {formattedError} = this.props;
    const promoCode = this.props.order.promoCodes && this.props.order.promoCodes[0];
    const {referralGift, deliveryWindow, isMembershipOrder} = this.props.order;

    const {startAt: deliveryStartDate, endAt: deliveryEndDate} = deliveryWindow;

    const title = `Order for ${deliveryTimeLabel(
      deliveryStartDate,
      deliveryEndDate,
      clientSettings.tzid,
    )} | Good Eggs`;

    return (
      <MarketLayout disableUpcomingOrdersBanner>
        <Helmet>
          <title>{title}</title>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${this.props.settings.googleMapsKey}&libraries=places`}
          />
        </Helmet>

        <div className="single-order-page">
          <div className="single-order__main-container">
            {formattedError ? (
              <div className="single-order-page__global-error">
                <Alert type="error" heading={formattedError.customerMessage}>
                  {formattedError.advice}
                </Alert>
              </div>
            ) : null}
            <div className="single-order__back-to-orders">
              <a className="single-order__back-to-orders-link" href="/account/orders">
                <i className="single-order__back-to-orders-link-chevron icon icon-chevron left" />
                <span> Back to your orders </span>
              </a>
            </div>
            <OrderPanel
              orderId={this.props.order.id}
              showMasqueradeWarning
              isEditable={this.props.order.isEditable}
              orderCutoff={this.props.order.orderCutoff}
              startAt={this.props.order.deliveryWindow.startAt}
              endAt={this.props.order.deliveryWindow.endAt}
              tzid={clientSettings.tzid}
              onOpenModal={this.onOpenModal}
              onCloseModal={this.onCloseModal}
              hasSubscribedItems={this.props.order.hasSubscribedItems}
              additionalPricingDetails={this.props.order.additionalPricingDetails}
              user={this.props.user}
            >
              <DeliveryTime />
              <DeliveryAddress />
              {this.props.order.isEditable ? <PaymentDetails /> : null}
              <OrderItems />

              <div className="single-order-page__totals-container" data-testid="totals-container">
                <DisplayTotals
                  displayTotals={this.props.order.displayTotals}
                  showDeliveryFee
                  isMembershipOrder={isMembershipOrder}
                />
              </div>

              <div className="single-order-page__totals-footer">
                {this.props.order.isEditable ? (
                  <CancelOrder isMasqueradingAfterCutoff={false} />
                ) : null}
                {!this.props.order.isEditable && this.props.user.masquerading ? (
                  <CancelOrder isMasqueradingAfterCutoff />
                ) : null}

                {promoCode || referralGift ? (
                  <div className="single-order-page__promocode-label">
                    Promo Code: {promoCode ? formatPromoCode(promoCode) : 'Referral'}
                  </div>
                ) : null}
              </div>

              {this.props.order.isEditable && this.props.order.hasSubscribedItems ? (
                <div className="single-order-page__manage-subscriptions">
                  <a
                    className="single-order-page__manage-subscriptions__link"
                    href="/account/schedule"
                  >
                    Manage Your Subscriptions Schedule
                  </a>
                </div>
              ) : null}
            </OrderPanel>
          </div>
        </div>
      </MarketLayout>
    );
  }
}

SingleOrderPage.pageName = 'Individual Order';

SingleOrderPage.propTypes = {
  dispatch: PropTypes.func,
  formattedError: PropTypes.string,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.shape({
    googleMapsKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect((state) => state)(SingleOrderPage);
